import React from 'react'
import './about.css'
export function About() {
  return (
<>
<div className='container'>
<p className="leadAbout">

 </p>

</div>
</>
  )
}

